export const hurlingItems = [
  {
    title: 'Home',
    icon: 'mdi-home',
    group: 'Dashboard',
    to: '/app/hurling/home',
  },
  {
    subheader: 'Apps',
    icon: 'mdi-apps-box',
    divider: true,
  },
  {
    title: 'Selection',
    icon: 'mdi-gesture-tap',
    group: 'hurling/selection',
    children: [
      {
        title: 'Current Gameweek',
        icon: 'mdi-clock-outline',
        to: 'current',
      },
      {
        title: 'My History',
        icon: 'mdi-history',
        to: 'history',
      },
      {
        title: 'Player Search',
        icon: 'mdi-magnify',
        to: 'search',
      }
    ],
  },
  {
    title: 'Leagues',
    icon: 'mdi-chart-line',
    group: 'hurling/leagues',
    children: [
      {
          title: 'Search',
          icon: 'mdi-magnify',
          to: 'search',
          smallIcon: true,
          evenMargin: true
      },
      {
        subheader: 'Public Leagues',
        divider: true,
        children: [
          {
            title: 'Overall',
            icon: 'mdi-earth',
            to: 'overall',
          },
          {
            title: 'County',
            icon: 'mdi-flag-outline',
            to: 'county',
          },
          {
            title: 'Club',
            icon: 'mdi-tshirt-crew-outline',
            to: 'club',
          }
        ],
      },
      {
        subheader: 'Private Leagues',
        divider: true,
        options: true,
        children: [
          
        ],
      }
    ],
  },
  {
    title: 'Profiles',
    icon: 'mdi-shield-outline',
    to: '/app/hurling/statistics/profiles',
  },
  {
    title: 'Matches',
    icon: 'mdi-calendar-month-outline',
    group: 'hurling/matches',
    children: [
      {
        title: 'Fixtures',
        icon: 'mdi-circle-medium',
        to: 'fixtures',
      },
      {
        title: 'Results',
        icon: 'mdi-circle-medium',
        to: 'results',
      }
    ],
  },
  {
    title: 'Standings',
    icon: 'mdi-tournament',
    group: 'hurling/standings',
    children: [
      {
        subheader: 'Provincials',
        divider: true,
        children: [
          {
            title: 'Munster',
            icon: 'mdi-circle-medium',
            to: 'munster',
          },
          {
            title: 'Leinster',
            icon: 'mdi-circle-medium',
            to: 'leinster',
          }
        ],
      },
      {
        subheader: 'Tier 2 - 5',
        divider: true,
        children: [
          {
            title: 'Joe McDonagh',
            icon: 'mdi-circle-medium',
            to: 'Joe McDonagh',
          },
          {
            title: 'Christy Ring',
            icon: 'mdi-circle-medium',
            to: 'Christy Ring',
          },
          {
            title: 'Nicky Rackard',
            icon: 'mdi-circle-medium',
            to: 'Nicky Rackard',
          },
          {
            title: 'Lory Meagher',
            icon: 'mdi-circle-medium',
            to: 'Lory Meagher',
          }
        ],
      }
    ],
  },
  {
    title: 'Permutations',
    icon: 'mdi-head-dots-horizontal-outline ',
    group: 'hurling/permutations',
    children: [
      {
        title: 'Munster',
        icon: 'mdi-circle-medium',
        to: 'munster',
      },
      {
        title: 'Leinster',
        icon: 'mdi-circle-medium',
        to: 'leinster',
      }
    ],
  },
  // {
  //   title: 'Insights',
  //   icon: 'mdi-head-snowflake-outline',
  //   group: 'insights',
  //   children: [
  //     // {
  //     //   title: 'You',
  //     //   icon: 'mdi-circle-medium',
  //     //   to: 'individual',
  //     // },
  // //     {
  // //       title: 'Your Club/League',
  // //       icon: 'mdi-circle-medium',
  // //       to: 'club',
  // //     },
  // //     {
  // //       title: 'Your County',
  // //       icon: 'mdi-circle-medium',
  // //       to: 'county',
  // //     },
  //     {
  //       title: 'Gameweek',
  //       icon: 'mdi-circle-medium',
  //       to: 'team',
  //     }
  //   ],
  // },
  {
    title: 'Statistics',
    icon: 'mdi-chart-box-outline',
    group: 'hurling/statistics',
    children: [
      {
        subheader: 'Team',
        divider: true,
        children: [
          {
            title: 'Honours',
            icon: 'mdi-trophy-variant-outline',
            to: 'honours',
          },
          {
            title: 'Head to Head',
            icon: 'mdi-account-group-outline',
            to: 'headtohead',
          },
          {
            title: 'Ranking',
            icon: 'mdi-order-numeric-ascending',
            to: 'ranking',
          }
        ],
      }
    ],
  },
  {
    subheader: 'Help',
    icon: 'mdi-apps-box',
    group: 'help',
    divider: true,
  },
  {
    title: 'FAQ',
    icon: 'mdi-frequently-asked-questions',
    group: 'pages',
    to: '/help/faq'
  },
  {
    title: 'About',
    icon: 'mdi-information-variant',
    group: 'pages',
    to: '/help/about'
  },
  {
    title: 'Legal',
    icon: 'mdi-scale-balance',
    group: 'pages',
    to: '/help/legal'
  },
]
