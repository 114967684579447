<template>
  <v-navigation-drawer v-model="getThemeMode.verticalSidebarDrawer" :color="$vuetify.theme.dark ? 'dark' : getThemeMode.verticalSidebarDrawerColor
    " :expand-on-hover.sync="getThemeMode.verticalSidebarMini" :mini-variant="getThemeMode.verticalSidebarMini"
    :src="bg" :dark="getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false" app
    :disable-resize-watcher="false" :mobile-breakpoint="960" height="100%" class="shadow-sm rounded-tr-lg rounded-br-lg"
    :floating="true" :right="$vuetify.rtl" v-bind="$attrs">
    <template v-slot:img="props">
      <v-img :style="{ opacity: opacity }" v-bind="props" class="test" />
    </template>
    <vue-perfect-scrollbar :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="h-100 rtl-ps-none ps scroll" style="height: 100%">
      <v-list dense nav class="pb-0">
        <v-list-item class="px-0">
          <v-list-item-avatar tile>
            <v-img v-if="getThemeMode.verticalSidebarDrawerColor == 'dark'" src="/assets/logo/ktgLogo.png" />
            <v-img v-else src="/assets/logo/ktgLogo.png" />
          </v-list-item-avatar>

          <v-list-item-title class="text-18 text-uppercase text-default text-wrap">
            <v-select class="no-upper-case pt-0 mt-0" :items="sports" v-model="currentMode" hide-details
              @change="changeSport"></v-select>
          </v-list-item-title>
          <v-scroll-x-transition>
            <v-btn icon @click.stop="toggleSidebar">
              <v-tooltip v-if="!getThemeMode.verticalSidebarMini" right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" color v-on="on">
                    mdi-circle-slice-8
                  </v-icon>
                </template>
                <span>UnPin</span>
              </v-tooltip>
              <v-tooltip v-if="getThemeMode.verticalSidebarMini" right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" color v-on="on">
                    mdi-circle-outline
                  </v-icon>
                </template>
                <span>pin</span>
              </v-tooltip>
            </v-btn>
          </v-scroll-x-transition>
        </v-list-item>
      </v-list>

      <v-list :key="currentMode">
        <template v-for="(item, i) in computedItems">
          <div v-if="item.subheader" :key="item.subheader" :class="miniVariant && 'px-1'">
            <v-subheader>
              {{ item.subheader }}
            </v-subheader>

            <v-divider />
          </div>

          <base-item-group v-if="item.children" :key="`group-${i}`" :item="item" :subGroup="item.subGroup"
            :noPadding="!!item.subGroup">
          </base-item-group>

          <base-item v-else-if="!item.subheader && !item.children" :key="`item-${i}`" :item="item" :no-padding="true"
            :disabled="item.disabled" />
        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { hurlingItems } from '../../data/hurlingNavigation'
import { footballItems } from '../../data/footballNavigation'

export default {
  data() {
    return {
      drawer: true,
      switch1: 'on',
      color: 'dark',
      colors: ['primary', 'blue', 'success', 'red', 'teal'],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false,
      currentMode: '',
      items: [],
      sports: [
        { text: "Hurling", value: "hurling" },
        { text: "Football", value: "football" }
      ],
      navData: null,
      opacity: 0.4,
    }
  },
  mounted() {
    this.currentMode = this.$router.currentRoute.meta.sport;
    this.navData = [];
    this.navData['hurling'] = hurlingItems;
    this.navData['football'] = footballItems;
  },
  computed: {
    ...mapGetters(['getThemeMode', 'loggedInUser', 'getPersonalLeagues', 'getUserClub']),
    bg() {
      return this.background
        ? 'https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'
        : undefined
    },
    getMiniVariant() {
      return this.miniVariant
    },
    computedItems() {
      if (!this.navData) return [];
      this.items = this.navData[this.currentMode];
      if (!this.items) return [];
      let list = this.items.map(this.mapItem);
      if (this.currentMode == 'hurling') {
        this.updateOverallLeague(list);
        this.addClubLeague(list);
        this.addCountyLeague(list);
        this.addPersonalLeagues(list);
      }
      return list;
    },
  },
  methods: {
    ...mapActions(['changeVerticalSidebarDrawer', 'changeVerticalSidebarMini']),
    toggleSidebar() {
      this.changeVerticalSidebarMini()
      this.expandOnHover = !this.expandOnHover
      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      }
    },
    updateOverallLeague(list) {
      if (this.$store.getters['getOverallLeagueId'] && list[3].children[1]) {
        list[3].children[1].children[0].to = this.$store.getters['getOverallLeagueId'];
      }
    },
    addClubLeague(list) {
      let leagues = this.$store.getters['getPersonalLeagues'];
      let clubLeague = leagues.find(it => it.club);
      if (clubLeague) {
        list[3].children[1].children[2].title = clubLeague.name;
        list[3].children[1].children[2].to = clubLeague.id;
      } else if (list[3].children[1]) {
        list[3].children[1].children[2].disabled = true;
      }
      return list;
    },
    addCountyLeague(list) {
      let leagues = this.$store.getters['getPersonalLeagues'];
      let countyLeague = leagues.find(it => it.location);
      if (countyLeague) {
        list[3].children[1].children[1].title = countyLeague.name;
        list[3].children[1].children[1].to = countyLeague.id;
      } else if (list[3].children[1]) {
        list[3].children[1].children[1].disabled = true;
      }
      return list;
    },
    addPersonalLeagues(list) {
      let leagues = this.$store.getters['getPersonalLeagues'];
      let personalLeagues = leagues.filter(it => it.owner);
      if (personalLeagues && personalLeagues.length > 0 && list[3].title == "Leagues") {
        for (let i = 0; i < personalLeagues.length; i++) {
          list[3].children[2].children.push({ "title": personalLeagues[i].name, "id": personalLeagues[i].id, "to": personalLeagues[i].id })
        }
      } else {
        if (list[3].title == "Leagues") {
          list[3].children[2].children.push({ "title": "None", "id": null, "to": null, "icon": "mdi-minus", "disabled": true })
        }
      }
      return list;
    },
    changeSport() {
      this.$router.push(`/app/${this.currentMode}/home`);
      this.items = this.navData[`${this.$router.currentRoute.meta.sport}`];
    }
  },
}
</script>

<style lang="scss" scoped>
.no-upper-case {
  text-transform: none !important;
}

.ps-container {
  position: inherit !important;
}

.app-admin-wrap-layout-1.sidebar-mini {
  .v-toolbar {
    left: 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .v-main {
    padding: 75px 0px 12px 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0px !important;
  }

  &.sidebar-close {
    .v-toolbar {
      left: 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }

    .v-main {
      padding: 75px 0px 12px 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0px !important;
    }
  }
}
</style>
